<template>
  <div>
    <admin-header v-if="course && training" :title="course.name" :links="bcLinks"></admin-header>
    <v-row>
      <v-col lg="12" cols="12"></v-col>
      <v-col class="mr-12" lg="5" md="5" sm="8" cols="12">
        <v-row class="d-flex flex-column">
          <v-col>
            <label class="textOrange--text title font-weight-regular">Instructor</label>
            <v-skeleton-loader v-if="loading" class="mt-2" type="list-item-avatar-two-line"></v-skeleton-loader>
            <v-card v-else tile class="d-flex align-center mt-2 mb-4">
              <v-avatar size="64" color="textOrange" tile>
                <v-img contain v-if="course.instructorPhoto" :src="course.instructorPhoto.url"></v-img>
                <span v-else class="white--text">{{ course.instructorName.charAt(0) }}</span>
              </v-avatar>
              <div class="ml-6 body-1">{{ course.instructorName }}</div>
            </v-card>
          </v-col>
          <v-col>
            <label class="textOrange--text title font-weight-regular">Agenda</label>
            <v-skeleton-loader v-if="loading" class="mt-2" type="card"></v-skeleton-loader>
            <v-card
              v-else
              flat
              class="card-shadow mt-2 pa-6 mb-4"
              transition="slide-y-reverse-transition"
            >
              <v-card class="mb-4" v-for="(agenda, index) in course.agenda" :key="index">
                <v-card-text>{{ agenda }}</v-card-text>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="5" md="5" sm="8" cols="12">
        <v-row class="d-flex flex-column">
          <v-col>
            <div class="textOrange--text title font-weight-regular">Prerequisites</div>
            <v-skeleton-loader v-if="loading" class="mt-2" type="card"></v-skeleton-loader>
            <v-card v-else class="card-shadow mt-2 pa-6 mb-6">
              <v-card
                color="transparent"
                class="d-flex align-center justify-center mx-auto"
                flat
                height="100"
                v-if="!course.prerequisites"
              >
                <v-card-text class="d-flex justify-center body-1">No prerequisites to preview</v-card-text>
              </v-card>
              <v-card
                v-else
                class="mb-4 pa-1"
                v-for="(prerequisite, index) in course.prerequisites"
                :key="index"
              >
                <div class="d-flex ml-4 subtitle-2 primary--text">{{ prerequisite }}</div>
              </v-card>
            </v-card>
          </v-col>
          <v-col>
            <label class="textOrange--text title font-weight-regular">Materials</label>
            <v-skeleton-loader v-if="loading" class="mt-2" type="card"></v-skeleton-loader>
            <v-card v-else flat class="card-shadow mt-2 pa-6 mb-4">
              <v-card
                class="pa-4 mb-4 d-flex"
                hover
                v-for="(material, index) in course.material"
                :key="index"
                @click="openFile(material.file.url)"
              >
                <v-card-text>
                  <div class="d-flex align-center">
                    <div>
                      <v-icon color="primary" size="32">mdi-paperclip</v-icon>
                    </div>
                    <div class="ml-4">
                      <div class="body-1 font-weight-regular text-capitalize">{{ material.name }}</div>
                      <div class="secondaryText--text">{{ material.description }}</div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-footer fixed class="py-4">
      <div class="d-flex full-width justify-center">
        <bz-button
          title="Start Submission Process"
          type="Submit"
          class="justify-self-center"
          :disabled="!canSubmit"
          :to="{
            name: 'submission',
            params: {
              trainingId: this.training._id,
              courseId: this.course._id
            }
          }"
        ></bz-button>
      </div>
    </v-footer>
  </div>
</template>
<style scoped>
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 59px;
  border-radius: 6px;
}
</style>
<script>
import AdminHeader from "@/beez-admin/components/AdminHeader";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import BzButton from "@shared/components/BzSubmitButton";
import moment from "moment";

export default {
  name: "user-course-info",
  components: {
    BzButton,
    AdminHeader
  },

  created() {
    this.refresh();
    
  },
  data() {
    return {
      trainingId: null,
      course: null,
      loading: false,
      training: null
    };
  },

  computed: {
    ...mapFields("userElevation", ["selectedTraining", "selectedCourse"]),
    bcLinks() {
      return [
        {
          text: "Trainings",
          disabled: false,
          to: {
            name: "user-training"
          }
        },
        {
          text: this.training.name || "",
          disabled: false,
          to: {
            name: "user-courses",
            params: {
              trainingId: this.training._id
            }
          }
        },
        {
          text: this.course.name,
          disabled: true
        }
      ];
    },
    canSubmit(){
      if (!this.course){
        return false;
      }

      // if course has no deadline then
      // we can always submit 
      if (!this.course.endDate){
        return true;
      }

      

      // check if current CET time is after course deadline
      // if it is then we can block submission
      // check dates only 
      if (moment().startOf('day').isAfter(moment(this.course.endDate).startOf('day'))){
        return false;
      }

      return true;
    }
  },

  methods: {
    ...mapActions("userElevation", {
      getCourse: "getCourse",
      getTraining: "getTraining"
    }),
    async refresh() {
      // try to take course and training from state
      // if not found then fetch them from server

      this.loading = true;

      if (
        this.selectedCourse &&
        this.selectedCourse._id === this.$route.params.id
      ) {
        this.course = this.selectedCourse;
        this.training = this.selectedTraining;
      }

      try {
        this.course = await this.getCourse(this.$route.params.id);
        this.training = await this.getTraining(this.course.trainingId);
      } finally {
        this.loading = false;
      }
    },
    openFile(value) {
      window.open(value, "_blank");
    },
    navigateToSubmission() {
      this.$router.push({
        name: "submission",
        params: {
          trainingId: this.trainingId,
          courseId: this.id
        }
      });
    }
  }
};
</script>